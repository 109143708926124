/*
 *  Created by : Vishal Mahajan
*   Created on : 02-Nov-2018
 *  Description: This component is used for add branch master.
 * */

// #region Imports
import { Component, OnInit, Inject, ViewChild, VERSION, ElementRef, Renderer } from '@angular/core';
import { MatDialogRef, MatAutocompleteModule, MatSelect, MAT_DIALOG_DATA } from '@angular/material';
import { RepositoryService } from '../../../Services/repository.service';
import { Validators, FormControl, FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { ToasterService, Toast, ToasterConfig, BodyOutputType } from 'angular2-toaster';
import { map, startWith, filter, takeUntil } from 'rxjs/operators';
import { BranchModel } from '../../../Models/branch-model';
import { RegionModel } from '../../../Models/region-model';
import { Observable } from 'rxjs';
import { CountryModel } from '../../../Models/country-model';
import { StateModel } from '../../../Models/state-model';
import { UserModel } from '../../../Models/user-model';
import { BranchCustomerCareRelationModel } from 'src/app/Models/branchCustomerCareRelation-model';
import { DatePipe } from '@angular/common';
// #endregion


@Component({
  selector: 'app-branch-edit-dialog',
  templateUrl: './branch-edit-dialog.component.html',
  styleUrls: ['./branch-edit-dialog.component.css']
})
export class BranchEditDialogComponent implements OnInit {
  // #region Variables Declarations
  public loggedUserID: string;
  regions: RegionModel[];
  users: UserModel[];
  selectedBMs: UserModel[];
  
  countries: CountryModel[];
  states: StateModel[];
  branch: BranchModel;
  addBranchForm: FormGroup;
  filteredOptions: Observable<RegionModel[]>;

  // #endregion Variables Declarations



  // #region Toaster Service registration and defualt position
  private toasterService: ToasterService;
  public config: ToasterConfig = new ToasterConfig({
    animation: 'fade',
    showCloseButton: true,
    tapToDismiss: false,
    positionClass: 'toast-top-center'
  });
  // #endregion

  // #region BM search

  bmSearch(query: string) {
    //debugger;
    const result = this.selectBMs(query);
    this.selectedBMs = result;
  }

  selectBMs(query: string): UserModel[] {


    const result: UserModel[] = [];
    for (const roleObj of this.users) {
      if ((roleObj.firstName + ' ' + roleObj.lastName).toLowerCase().indexOf(query) > -1) {
        result.push(roleObj);
      }
    }
    return result;
  }
  // #endregion

  ngOnInit() {
    this.buildForm();
    this.loggedUserID = localStorage.getItem('userId');

  }

  //#region Build Form and validation
  buildForm() {



    this.addBranchForm = this.fb.group({
      'branchCode': [this.data.branchCode, [
        Validators.required,
        Validators.pattern('^[0-9]{1,3}'),
      ]
      ],
      'branchName': [this.data.branchName, [
        Validators.required
      ]
      ],
      'address1': [this.data.address1, [
        Validators.required
      ]
      ]
      ,
      'address2': [this.data.address2, [
        // Validators.required
      ]
      ],
      'email': [this.data.email, [
        // Validators.required,
        Validators.pattern('^[a-z0-9._]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]
      ],
      'address3': [this.data.address3, [
        // Validators.required
      ]
      ],
      'regionId': [this.data.regionId == null ? '0' : this.data.regionId.toString(), [
        Validators.required,
        Validators.pattern('^[1-9][0-9]*$'),
      ]
      ],

      'state': [this.data.state == null ? '0' : this.data.state.toString(), [

      ]
      ],
      'city': [this.data.city, [

      ]
      ],
      'pin': [this.data.pin, [
        Validators.pattern('^[0-9]{6}'),
      ]
      ],
      'phoneNo1': [this.data.phoneNo1, [
        Validators.pattern('^[0-9]{0,10}'),
      ]
      ],
      'phoneNo2': [this.data.phoneNo2, [
        Validators.pattern('^[0-9]{0,10}'),
      ]
      ],
      'branchManagerId': [this.data.branchManagerId, [
        Validators.required,
        //Validators.pattern('^[1-9][0-9]*$'),
      ]
      ],
      'asstBranchManagerId': [this.data.asstBranchManagerId == '0' ? '' : this.data.asstBranchManagerId, [
        //Validators.required
      ]
      ],



    });

    this.addBranchForm.valueChanges.subscribe(
      data => this.onValueChanged(data)

    );
    this.onValueChanged();
  }


  formErrors = {
    'branchCode': '',
    'branchName': '',
    'address1': '',
    'email': '',
    'regionId': '',
    'branchManagerId': '',
    'phoneNo1': '',
    'phoneNo2': '',
    'pin': '',

  };
  validationMessages = {
    'branchCode': {
      'required': 'Please enter branch code',
      'pattern': 'Please enter only number and max. lenght is 3'
    },
    'branchName': {
      'required': 'Please enter branch name'
    },
    'address1': {
      'required': 'Please enter address1',

    },
    'email': {
      'pattern': 'Please enter valid email'
    },
    'regionId': {
      'required': 'Please enter region',
      'pattern': 'Please enter region',

    },
    'pin': {
      'pattern': 'Please enter valid pin'
    },
    'branchManagerId': {
      'required': 'Please select branch manager',
      'pattern': 'Please select branch manager',

    },
    'userName': {
      'required': 'Please enter user name',

    },
    'phoneNo1': {
      'pattern': 'Please enter valid contact no. and max length is 10'
    },
    'phoneNo2': {
      'pattern': 'Please enter valid other contact no. and max length is 10'
    },
  };

  onValueChanged(data?: any) {

    if (!this.addBranchForm) {
      return;
    }
    const form = this.addBranchForm;

    for (const field in this.formErrors) {

      if (Object.prototype.hasOwnProperty.call(this.formErrors, field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }
 //#endregion



  // #region Toaster  Pop ups for actions
  showUpdateSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Updated!');
  }
  showDuplicate() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'Duplicate', 'Duplicate Record!');
  }
  showSuccess() {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('success', 'Success', 'Record Created!');
  }
  showError(responseMessage) {
    // this.config.positionClass = 'toast-top-center';
    this.toasterService.pop('error', 'error', responseMessage);
  }
  // #endregion Toaster  Pop ups for actions

  // #region Constructors
  constructor(public dialogRef: MatDialogRef<BranchEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BranchModel,
    private repository: RepositoryService,
    toasterService: ToasterService, private fb: FormBuilder) {

    this.toasterService = toasterService;
    this.LoadDDLData();

  }
  // #endregion Constructors 


 
  submit() {
    // emppty stuff
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  // #region Edit Dialog Save action
  stopEdit(): void {


  }
  // #endregion

  //#region loads the all DDL data from web api
  public LoadDDLData() {
    this.LoadRegion();
    this.LoadCountry();
    this.LoadState();
    this.LoadUsers();
  }
  //#endregion loads the source data from web api

  //#region loads region data from web api
  public LoadRegion() {

    let apiAddress: string = "api/region";

    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.regions = res as RegionModel[];
        //console.log(this.regions);
        //this.filteredOptions = this.formControl.valueChanges
        //  .pipe(
        //    startWith<string | RegionModel>(''),
        //    map(value => typeof value === 'string' ? value : value.regionName),
        //    map(name => name ? this._filter(name) : this.regions.slice())
        //  );
      });
  }
  private _filter(name: string): RegionModel[] {
    const filterValue = name.toLowerCase();

    return this.regions.filter(option => option.regionName.toLowerCase().indexOf(filterValue) === 0);
  }
  //#endregion loads region data from web api
  public LoadUsers() {
    let apiAddress: string = "api/user";
    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.users = res as UserModel[];
        this.selectedBMs = this.users;
      });
  }
  //#region loads country data from web api
  public LoadCountry() {

    let apiAddress: string = "api/country";

    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.countries = res as CountryModel[];
        //console.log(this.countries);
      });
  }
  //#endregion loads country data from web api

  //#region loads state data from web api
  public LoadState() {

    let apiAddress: string = "api/state";

    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.states = res as StateModel[];
        //console.log(this.states);
      });
  }
  //#endregion loads state data from web api

  // #region Get Branch for Edit Dialog Methods
  public LoadBranch(id: number) {
    let apiAddress: string = "api/branch/" + id;

    this.repository.getData(apiAddress)
      .subscribe(res => {
        this.branch = res as BranchModel;
        this.data = this.branch;
       // console.log(this.branch);

      });
  }
  // #endregion Get Branch for Edit Dialog Methods

  // #region Add Dialog Save action
  public confirmAdd(form: NgForm): void {



    // stop here if form is invalid
    if (this.addBranchForm.invalid) {
      return;
    }
    const result1 = JSON.parse(JSON.stringify(form));


    let postmodel: BranchModel = {
      id: this.data.id,
      branchName: result1.branchName.trim(),
      regionId: result1.regionId,
      branchCode: result1.branchCode,
      address1: result1.address1,
      address2: result1.address2,
      address3: result1.address3,
      state: result1.state,
      city: result1.city,
      pin: result1.pin,
      phoneNo1: result1.phoneNo1,
      phoneNo2: result1.phoneNo2,
      email: result1.email,
      branchManagerId: result1.branchManagerId,
      asstBranchManagerId: result1.asstBranchManagerId == '' ? null : result1.asstBranchManagerId,
      active: 1,
      createdBy: null,
      createdOn: null,
      modifiedBy: this.loggedUserID,
      modifiedOn: null
    }
    let apiAddress: string = "api/branch/";

    this.repository.update(apiAddress, postmodel)
      .subscribe(res => {

        if (res.responseCode == 'Conflict') {
          //console.log("conflict" + res.responseCode);
          this.showDuplicate();
        }
        else if (res.responseCode == 'OK') {
          this.showUpdateSuccess();
          this.dialogRef.close(res);

        } else if (res.responseCode == 'InternalServerError') {
          this.showError(res.responseMessage);
          this.dialogRef.close(res);
        }

      });
  }
  // #endregion Add Dialog Save action

}
